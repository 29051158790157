import BaseClient from '@/service/client/BaseClient';
import { ResponseData } from '@/service/client/BaseClient.type';
import {
  CreatePrintTaskDto,
  PrintDataVo,
  PrintTaskEntity,
  SearchPrintTaskListReq,
} from '@/service/client/Print.type';
import { ListResult } from '../model/types';

class _PrintClient extends BaseClient {
  public async printData(
    order_id: string,
    tid: string
  ): Promise<ResponseData<PrintDataVo>> {
    return this.get('/print/data', { id: order_id, tenant_id: tid });
  }

  public async createPrintTask(
    params: CreatePrintTaskDto
  ): Promise<ResponseData<boolean>> {
    return this.post('/print/task', params);
  }

  public async getTaskList(
    params: SearchPrintTaskListReq
  ): Promise<ResponseData<ListResult<PrintTaskEntity>>> {
    return this.get('/print/task-list', params);
  }

  public async getTaskList_Test(): Promise<
    ResponseData<ListResult<PrintTaskEntity>>
  > {
    return Promise.resolve({
      success: true,
      code: 200,
      message: '',
      data: {
        total: 0,
        list: [],
      },
    });
  }

  public async taskDetail(id: number): Promise<ResponseData<PrintTaskEntity>> {
    return this.get('/print/task-detail', { id });
  }
}

export const PrintClient = new _PrintClient();
