import { envInfo } from '@/configuration';
import { useUserStore } from '@/pinia/user';

export const initPermission = async () => {
  const config = envInfo();
  if (config.withoutUser.includes(window.location.pathname)) {
    return true;
  }

  const userStore = useUserStore();
  await userStore.getUserInfo();

  return true;
};

export const hasPermission = (key: string) => {
  const userStore = useUserStore();
  const found = userStore.model?.resources?.find((d) => d.identifier === key);
  return Boolean(found);
};
