/* unplugin-vue-components disabled */import { defineComponent, computed } from 'vue';
import { ElConfigProvider } from 'element-plus';
import 'element-plus/es/components/config-provider/style/index'

import { RouterView } from 'vue-router';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import { useRoute } from 'vue-router';
// import TaskCenter from '@/common/task-center';
export default defineComponent({
  components: {
    ElConfigProvider,
    RouterView
  },
  setup() {
    const size = 'default';
    const locale = zhCn;
    const route = useRoute();
    const shouldKeepAlive = computed(() => route?.meta?.keep);
    return {
      zIndex: 3000,
      size,
      locale,
      shouldKeepAlive,
      route
    };
  }
});