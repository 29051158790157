export interface EnvInfoData {
  baseUrl: string;
  /**
   * 请求接口时, 不需要判断是否登录的 白名单
   */
  authWhiteList: string[];
  /**
   * 页面进入的时候, 不需要初始化 权限的页面
   */
  withoutUser: string[];
  loginPage: string;
  env: 'dev' | 'prod';
}

const env: Record<'development' | 'production', EnvInfoData> = {
  development: {
    baseUrl: 'http://localhost:3000/',
    authWhiteList: [
      '/user/login',
      '/user/logout',
      '/tenant/domain',
      '/print/data',
    ],
    withoutUser: ['/platform/login', '/platform/server-print'],
    loginPage: '/login',
    env: 'dev',
  },
  production: {
    baseUrl: 'https://www.bpaos.com/',
    authWhiteList: [
      '/user/login',
      '/user/logout',
      '/tenant/domain',
      '/print/data',
    ],
    withoutUser: ['/platform/login', '/platform/server-print'],
    loginPage: '/login',
    env: 'prod',
  },
};

export const envInfo = () => {
  return env[process.env.NODE_ENV as 'development' | 'production'];
};
