import { DeliveryStatusEnum } from '@/service/client/Delivery.type';
import { OrderTypeEnum } from '@/service/model/enums';
import { OptionItem } from '@/service/model/types';

export const LayoutComponentsData = [];

export const DataSourceOfField: OptionItem[] = [
  { label: '公司基础信息', value: 'tenant' },
  { label: '商品类型', value: 'goods_type' },
  { label: '生产商', value: 'producer' },
  { label: '颜色', value: 'colors' },
  { label: '品牌', value: 'brand' },
  { label: '安全技术类别', value: 'security-kind' },
  { label: '安全技术类别分类', value: 'security-kind-type' },
  { label: '执行标准', value: 'standards' },
  { label: '水洗标组', value: 'washing_group' },
  { label: '尺码表', value: 'sizes' },
  { label: '企业69码', value: 'goods_code' },
  { label: '品名', value: 'prodcut-name' },
];

export const DataSourceOfFieldMap = {
  tenant: '公司基础信息',
  goods_type: '商品类型',
  producer: '生产商',
  colors: '颜色',
  brand: '品牌',
  'security-kind': '安全技术类别',
  standards: '执行标准',
  washing_group: '水洗标组',
  sizes: '尺码表',
  goods_code: '企业69码',
  'security-kind-type': '安全技术类别分类',
};

// TODO: 这里改, 服务端 批量导入sku的接口中 也要改;
export const CategoryMapping: Record<string, string> = {
  clothing: '服装',
  shoes: '鞋',
  pack: '包',
  socks: '袜子',
  glove: '手套',
  hat: '帽子',
  bra: '文胸',
  scarf: '围巾',
};

export const CategoryOpts: Array<OptionItem> = Object.keys(CategoryMapping).map(
  (d) => ({
    label: CategoryMapping[d],
    value: d,
  })
);

// TODO: 这里改, 服务端 批量导入sku的接口中 也要改;
export const KindMapping: Record<string, string> = {
  audlt: '成人',
  child: '儿童',
  baby: '婴儿',
};

export const KindOpts: Array<OptionItem> = Object.keys(KindMapping).map(
  (d) => ({
    label: KindMapping[d],
    value: d,
  })
);

// TODO: 这里改, 服务端 批量导入sku的接口中 也要改;
export const SexMapping: Record<string, string> = {
  male: '男',
  female: '女',
};

export const SexOpts: Array<OptionItem> = [
  { label: '男', value: 'male' },
  { label: '女', value: 'female' },
];

// TODO: 这里改, 服务端 批量导入sku的接口中 也要改;
export const TypesOpts: Array<OptionItem> = [
  { label: '上装', value: 'tops' },
  { label: '下装', value: 'bottoms' },
  { label: '套装', value: 'suit' },
  { label: '套裙', value: 'skirt' },
  { label: '亲子装', value: 'parent_child' },
  { label: '通码下装', value: 'fre_bottoms' },
  { label: '连体衣', value: 'bodysuit' },
  { label: '连体衣（无上装）', value: 'bodysuit_no_tops' },
];

export const TypeMapping: Record<string, string> = TypesOpts.reduce(
  (acc, item) => {
    acc[item.value] = item.label;
    return acc;
  },
  {}
);

export const ComponentTypeMap = {
  field: '动态字段',
  'bar-code': '条形码',
  image: '图片',
  'washing-h': '横向洗涤标',
  'washing-v': '纵向洗涤标',
  'qr-code': '二维码',
  'rich-text': '富文本',
};

export enum OrderStatus {
  /**
   * 新创建, 待提交审核
   */
  New = 1,
  /**
   * 已提交审核, 待审核
   */
  Submited = 6,
  /**
   * 已作废(不结算)
   */
  Droped = 3,
  /**
   * 已生产作废(结算)
   */
  ProductedDroped = 10,
  /**
   * 审核通过
   */
  Audited = 2,
  /**
   * 审核不通过
   */
  Rejected = 4,
  /**
   * 生产中
   */
  Producting = 5,
  /**
   * 生产完成
   */
  Producted = 7,
  /**
   * 已出货
   */
  Deliveryed = 8,
  /**
   * 已确认收货
   */
  Confirmed = 9,
}

export const OrderStatusMapping: Record<OrderStatus, string> = {
  [OrderStatus.New]: '待提交审核',
  [OrderStatus.Submited]: '待审核',
  [OrderStatus.Droped]: '已作废',
  [OrderStatus.ProductedDroped]: '已生产作废',
  [OrderStatus.Audited]: '审核通过',
  [OrderStatus.Rejected]: '审核未通过',
  [OrderStatus.Producted]: '生产完成',
  [OrderStatus.Producting]: '生产中',
  [OrderStatus.Deliveryed]: '配送中',
  [OrderStatus.Confirmed]: '已确认收货',
};

export const OrderTypeMapping: Record<OrderTypeEnum, string> = {
  [OrderTypeEnum.Normal]: '正常订单',
  [OrderTypeEnum.Logistics]: '物流补单',
};

export const OrderStatusOptions: Array<OptionItem<number>> = Object.keys(
  OrderStatusMapping
).map((key) => ({
  label: OrderStatusMapping[key],
  value: Number(key),
}));

export const OrderTypeOptions: Array<OptionItem<number>> = Object.keys(
  OrderTypeMapping
).map((key) => ({
  value: Number(key),
  label: OrderTypeMapping[key],
}));

export const SupplierStatusMapping: Record<number, string> = {
  [OrderStatus.Audited]: '待接单',
  [OrderStatus.Producting]: '生产中',
  [OrderStatus.Producted]: '生产完成',
  [OrderStatus.Deliveryed]: '已出货',
  [OrderStatus.Confirmed]: '已确认收货',
};

export const DeliveryStatusMapping: Record<DeliveryStatusEnum, string> = {
  [DeliveryStatusEnum.New]: '新创建',
  [DeliveryStatusEnum.Confirmed]: '已确认',
  [DeliveryStatusEnum.Removed]: '已作废',
};

/**
 * 动态字段 具名消费, 要求商品信息字段的code必须是这些, 不然程序不能正常读到数据;
 */
export enum HardCodeField {
  // 颜色_英文
  COLOR = 'colour',
  // 颜色_中文
  COLOR_CN = 'colorch',
  // 尺码
  SIZE = 'size',
  // 款号
  STYLE_NUMBER = 'ModelNumber',
  // 品名
  PRODUCT_NAME = 'ProductName',
  // 成分
  Ingredient = 'Component',
  // 唯一码
  UniqueCode = 'UniqueCode',
  // 二维码-唯一码
  UniqueQrCode = 'UniqueQrCode',
  // 批次
  BatchCode = 'batches',
  // 洗涤组
  WASHING_GROUP = 'WashingGroup',
  // 特殊说明
  Special_comment = 'SpecialComment',
  // sku 69码
  SKU_69Code = 'Sku69Code',
  // 产地
  OriginCity = 'OriginCity',
  // 产标
  ProducerFlag = 'ProducerFlag',
  // 检验员
  InspectorName = 'InspectorName',
}

export const Key_Menu_Click = 'key_menu_click_bpaos';
