export const imgSize = async (
  file: File
): Promise<{ width: number; height: number }> => {
  const fileData = file;

  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.onload = function (e) {
      const data = e.target?.result;

      const image = new Image();

      image.onload = function () {
        const width = image.width;
        const height = image.height;

        resolve({
          width,
          height,
        });
      };
      image.src = data as string;
    };

    reader.readAsDataURL(fileData);
  });
};

export const verifyPwd = (pwd: string): string => {
  let err = '';
  if (pwd.length < 7) {
    err = '密码不能少于7位';
  }
  if (!/[a-z]+/.test(pwd)) {
    err = '密码必需包括小写英文字母';
  }
  if (!/[A-Z]+/.test(pwd)) {
    err = '密码必需包括大写英文字母';
  }
  if (!/\d+/.test(pwd)) {
    err = '密码必需包括数字';
  }

  return err;
};

export const toJSON = <T>(jsonStr: string): T => {
  let ret: T;
  try {
    ret = JSON.parse(jsonStr) as T;
  } catch (error) {
    ret = new Function(`${jsonStr}`)() as T;
  }

  return ret;
};

export const getIntId = () => {
  const timeStr = Date.now().toString();
  const part5 = timeStr.slice(timeStr.length - 5);
  const randam = Math.floor(Math.random() * 20) + 1;

  return Number(`${part5}${randam}`);
};

export const encodeRichText = (str: string) => {
  return str.replaceAll(' ', '&nbsp;').replaceAll('\n', '<br />');
};

export const decodeRichText = (str = '') => {
  return str.replaceAll('&nbsp;', ' ').replaceAll('<br />', '\n');
};

export const is = <T>(str: T, type: 'String' | 'Array' | 'Object') => {
  return Object.prototype.toString.call(str) === `[object ${type}]`;
};

export const canvas2File = (
  canvas: HTMLCanvasElement,
  type = 'image/png',
  name = 'image.png'
): Promise<File | null> => {
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob) {
        const file = new File([blob], name, { type });
        resolve(file);
      }
      reject(null);
    }, type);
  });
};

export const downloadUrl = (url: string, name: string): void => {
  const a = document.createElement('a');
  a.download = name;
  a.href = url;
  a.style.position = 'fixed';
  a.style.top = '-9999px';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
